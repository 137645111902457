import { useLocale } from 'next-intl';
import { useSearchParams } from 'next/navigation';

import { useAuthData } from '@/hooks/useAuthData';
import { usePathname } from '@/navigation';
import { LOCALES, LOCALE_LABELS } from '@/utils';

import UserIcon from '../icons/UserIcon';
import { AccountMenuItem } from './AccountMenuItem';
import { DesktopMenuItem } from './DesktopMenuItem';
import { ACCOUNT_MENU_ITEMS, USER_AUTH_MENU_ITEMS } from './constants';
import { MenuItem as MenuItemType } from './constants';

type StandardDesktopMenuItemsProps = {
  isSubMenuOpen: string;
  isTransparentMode: boolean;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onMouseEnter: (
    e: React.MouseEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onSubMenuClick: (
    menuItem: MenuItemType,
    e: React.MouseEvent<HTMLElement>
  ) => void;
  onAccountSubMenuClick: (
    menuItem: MenuItemType,
    e: React.MouseEvent<HTMLElement>
  ) => void;
  onLocaleMenuClick: (
    menuItem: MenuItemType,
    e: React.MouseEvent<HTMLElement>
  ) => void;
};

export default function StandardDesktopMenuItems({
  isSubMenuOpen,
  isTransparentMode,
  onKeyDown,
  onMouseEnter,
  onAccountSubMenuClick,
  onSubMenuClick,
  onLocaleMenuClick,
}: StandardDesktopMenuItemsProps) {
  const { isAuthenticated } = useAuthData();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const locale = useLocale();

  const localeMenuItem = {
    key: 'locale',
    label: locale.toLocaleUpperCase(),
    subMenu: LOCALES.map(
      (l) =>
        ({
          key: l,
          label: LOCALE_LABELS[l],
          locale: l,
          isSelected: locale === l,
          url: `${pathname}${searchParams ? `?${searchParams}` : ''}`,
        } as MenuItemType)
    ),
  };

  return (
    <>
      {isAuthenticated ? (
        <AccountMenuItem
          key={ACCOUNT_MENU_ITEMS.key}
          menuItem={ACCOUNT_MENU_ITEMS}
          isCurrentRoute={false}
          isOpen={isSubMenuOpen === ACCOUNT_MENU_ITEMS.key}
          withCaret
          isTransparentMode={isTransparentMode}
          icon={<UserIcon />}
          onKeyDown={onKeyDown}
          onMouseEnter={onMouseEnter}
          onSubMenuClick={onAccountSubMenuClick}
        />
      ) : (
        <DesktopMenuItem
          key={USER_AUTH_MENU_ITEMS.key}
          menuItem={USER_AUTH_MENU_ITEMS}
          isCurrentRoute={false}
          isOpen={isSubMenuOpen === USER_AUTH_MENU_ITEMS.key}
          withCaret
          isTransparentMode={isTransparentMode}
          icon={<UserIcon />}
          onKeyDown={onKeyDown}
          onMouseEnter={onMouseEnter}
          onSubMenuClick={onSubMenuClick}
        />
      )}
      <DesktopMenuItem
        key={localeMenuItem.key}
        menuItem={localeMenuItem}
        isCurrentRoute={false}
        isOpen={isSubMenuOpen === localeMenuItem.key}
        withCaret
        withBorder={true}
        isTransparentMode={isTransparentMode}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        onSubMenuClick={onLocaleMenuClick}
      />
    </>
  );
}
